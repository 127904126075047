import { EventAggregator }                 from 'aurelia-event-aggregator';
import { inject }                          from 'aurelia-framework';
import { I18N }                            from 'aurelia-i18n';
import { BooleanStatus }                   from 'modules/administration/models/boolean-status';
import { LayersRepository }                from 'modules/management/bituminous-mixtures/layers/services/repository';
import { StudyTypesRepository }            from 'modules/management/bituminous-mixtures/study-types/services/repository';
import { HighwaysRepository }              from 'modules/management/concessions/concessions-tree/highways/services/repository';
import { InterventionTypesRepository }     from 'modules/management/concessions/concessions-tree/intervention-types/services/repository';
import { LotInterventionsRepository }      from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { EntitiesRepository }              from 'modules/management/concessions/entities/services/repository';
import { TeamsRepository }                 from 'modules/management/concessions/teams/services/repository';
import { ConcessionsRepository }           from 'modules/management/specifications/concessions/services/repository';
import { RevisionMaterialTypesRepository } from 'modules/management/specifications/specifications/specification-revisions/specification-revision-material-types/services/repository';
import { BaseFilterFormSchema }            from 'resources/classes/base-filter-form-schema';
import { SessionStorage }                  from 'resources/services/session-storage';

@inject(SessionStorage, EventAggregator, I18N, ConcessionsRepository, EntitiesRepository, TeamsRepository, HighwaysRepository, InterventionTypesRepository, LotInterventionsRepository, StudyTypesRepository, RevisionMaterialTypesRepository, LayersRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        created_by:      [],
        created_at_from: null,
        created_at_to:   null,
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param concessionsRepository
     * @param entitiesRepository
     * @param teamsRepository
     * @param highwaysRepository
     * @param interventionTypesRepository
     * @param lotInterventionsRepository
     * @param studyTypesRepository
     * @param materialTypesRepository
     * @param layersRepository
     */
    constructor(storage, eventAggregator, i18n, concessionsRepository, entitiesRepository, teamsRepository, highwaysRepository, interventionTypesRepository, lotInterventionsRepository, studyTypesRepository, materialTypesRepository, layersRepository) {
        super(storage, eventAggregator, i18n);

        this.concessionsRepository       = concessionsRepository;
        this.entitiesRepository          = entitiesRepository;
        this.teamsRepository             = teamsRepository;
        this.highwaysRepository          = highwaysRepository;
        this.interventionTypesRepository = interventionTypesRepository;
        this.lotInterventionsRepository  = lotInterventionsRepository;
        this.studyTypesRepository        = studyTypesRepository;
        this.materialTypesRepository     = materialTypesRepository;
        this.layersRepository            = layersRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.number_from = {
            type:     'int',
            key:      'number_from',
            label:    'form.field.study-number(from)',
            size:     3,
            required: false,
        };

        this.number_to = {
            type:     'int',
            key:      'number_to',
            label:    'form.field.study-number(to)',
            size:     3,
            required: false,
        };

        this.concession_id = {
            type:         'select2',
            key:          'concession_id',
            label:        'form.field.concession',
            size:         6,
            required:     false,
            remoteSource: () => this.concessionsRepository.allByLoggedUser(),
            observers:    [
                async (newValue, oldValue) => {
                    if (!newValue) {
                        viewModel.filterModel.highway_id           = null;
                        viewModel.filterModel.intervention_type_id = null;
                    }

                    await this.highwaysRepository.lotGroupedAll({
                        concession_id: newValue,
                    }).then((response) => {
                        this.highway_id.options = this.highway_id.instance.transformOptionsObject(response);
                    });

                    await this.lot_intervention_id.instance.fetchData();
                },
            ],
        };

        this.highway_id = {
            type:         'select2',
            key:          'highway_id',
            label:        'form.field.highway-road',
            size:         6,
            required:     false,
            remoteSource: () => this.highwaysRepository.lotGroupedAll({
                concession_id: viewModel.filterModel.concession_id,
            }),
            observers:    [
                (newValue, oldValue) => {
                    this.intervention_type_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.highwaysRepository.interventionTypes(newValue, { filter_by_logged_user: true }).then((response) => {
                            this.intervention_type_id.options = response;
                            this.intervention_type_id.instance.enable(response.length);
                        });
                    } else {
                        this.intervention_type_id.options = [{ id: null, name: this.i18n.tr('form.field.select-first-highway-road'), status_id: BooleanStatus.INACTIVE }];
                    }
                },
            ],
        };

        this.intervention_type_id = {
            type:         'select2',
            key:          'intervention_type_id',
            label:        'form.field.intervention-type',
            size:         6,
            required:     false,
            remoteSource: () => {
                return viewModel.filterModel.highway_id
                    ? this.highwaysRepository.interventionTypes(viewModel.filterModel.highway_id, { filter_by_logged_user: true })
                    : Promise.resolve([{ id: null, name: this.i18n.tr('form.field.select-first-highway-road'), status_id: BooleanStatus.INACTIVE }]);
            },
            observers:    [
                (newValue, oldValue) => {
                    return this.lotInterventionsRepository.lotGroupedAll({
                        concession_id:        viewModel.filterModel.concession_id,
                        highway_id:           viewModel.filterModel.highway_id,
                        intervention_type_id: newValue,
                    }).then((response) => {
                        this.lot_intervention_id.options = this.lot_intervention_id.instance.transformOptionsObject(response);
                    });
                },
            ],
        };

        this.lot_intervention_id = {
            type:         'select2',
            key:          'lot_intervention_id',
            label:        'form.field.lot-intervention',
            size:         6,
            required:     false,
            remoteSource: () => this.lotInterventionsRepository.lotGroupedAll({
                concession_id:        viewModel.filterModel.concession_id,
                highway_id:           viewModel.filterModel.highway_id,
                intervention_type_id: viewModel.filterModel.intervention_type_id,
            }),
            observers:    [
                (newValue) => {
                    this.entitiesRepository.activeUserEntities({ lot_intervention_id: newValue }).then((response) => {
                        this.entity_id.options = response;
                    });
                },
            ],
        };

        this.entity_id = {
            type:         'select2',
            key:          'entity_id',
            label:        'form.field.entity',
            remoteSource: () => this.entitiesRepository.activeUserEntities(),
            observers:    [
                (newValue) => {
                    let target = this.team_id;

                    if (target.instance)
                        target.instance.deselectAll();

                    if (newValue) {
                        target.attributes.disabled = false;

                        target.remoteSource = () => this.entitiesRepository.teams(newValue);
                        target.instance.fetchData();
                    } else {
                        target.attributes.disabled = true;
                    }
                },
            ],
            size:         6,
            required:     false,
        };

        this.team_id = {
            type:         'multiselect-native',
            key:          'team_id',
            label:        'form.field.team',
            remoteSource: () => (viewModel.filterModel.entity_id)
                ? this.entitiesRepository.teams(viewModel.filterModel.entity_id)
                : Promise.resolve([]),
            size:         6,
            required:     false,
            attributes:   {
                disabled: !viewModel.filterModel.entity_id,
            },
        };

        this.date_from = {
            type:     'material-ui-date-picker',
            key:      'date_from',
            label:    'form.field.date(from)',
            size:     3,
            required: false,
        };

        this.date_to = {
            type:     'material-ui-date-picker',
            key:      'date_to',
            label:    'form.field.date(to)',
            size:     3,
            required: false,
        };

        this.approval_date_from = {
            type:     'material-ui-date-picker',
            key:      'approval_date_from',
            label:    'form.field.approval-date(from)',
            size:     3,
            required: false,
        };

        this.approval_date_to = {
            type:     'material-ui-date-picker',
            key:      'approval_date_to',
            label:    'form.field.approval-date(to)',
            size:     3,
            required: false,
        };

        this.study_types = {
            type:         'multiselect-native',
            key:          'study_types',
            label:        'form.field.study-type',
            size:         6,
            required:     false,
            remoteSource: () => this.studyTypesRepository.byLotIntervention(viewModel.lot_intervention_id),
        };

        this.material_types = {
            type:         'multiselect-native',
            key:          'material_types',
            label:        'form.field.material-type',
            idPrefix:     this.schemaSlug,
            size:         6,
            required:     false,
            remoteSource: () => this.materialTypesRepository.allFromSector(viewModel.sectorId, {
                lot_intervention_id: viewModel.lot_intervention_id,
            }),
            attributes:   {
                disabled: readonly,
            },
        };

        this.layers = {
            type:         'multiselect-native',
            key:          'layers',
            label:        'form.field.layer',
            size:         6,
            required:     false,
            remoteSource: () => this.layersRepository.all(),
        };

        this.validated = {
            type:     'boolean-options-select',
            key:      'validated',
            label:    'form.field.validated?',
            size:     3,
            required: false,
        };

        this.created_by = {
            type:     'user-entity-team-lot-multiselect',
            key:      'created_by',
            label:    'form.field.created-by',
            size:     3,
            required: false,
        };

        this.created_at_from = {
            type:     'material-ui-date-picker',
            key:      'created_at_from',
            label:    'form.field.created-at(from)',
            size:     3,
            required: false,
        };

        this.created_at_to = {
            type:     'material-ui-date-picker',
            key:      'created_at_to',
            label:    'form.field.created-at(to)',
            size:     3,
            required: false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn',
                style: 'background-color: #0092D5 !important; border-color: #0092D5 !important; color: #ffffff !important;',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', {
                listingId:      viewModel.listingId,
                ignoredFilters: viewModel.appContainer.authenticatedUser.isBuilder ? ['entity_id', 'entity_name', 'team_id', 'team_name'] : [],
            }),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.number_from, this.number_to, this.concession_id],
            [this.highway_id, this.intervention_type_id],
            [this.lot_intervention_id, this.study_types],
            [this.material_types, this.layers],
            [this.date_from, this.date_to, this.approval_date_from, this.approval_date_to],
            [this.entity_id, this.team_id],
            [this.validated, this.created_by, this.created_at_from, this.created_at_to],
            [this.buttons],
        ];
    }
}
